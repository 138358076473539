import { css, cx } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { AgpProfile } from '@/uiKit/molecules/graphs/AgpProfile';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBars';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  glycemiaType?: string;
  width?: string;
};

export const GlycemiaStatsCGM = ({
  patientId,
  from,
  to,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from.toISO() as string,
      to: to.toISO() as string,
      glycemiaMeasurmentMethod: 'cgm',
    },
    {
      enabled: !!patientId,
    },
  );

  const styles = useStyles(makeStyles);

  const { data: glycemiaParameters } =
    Queries.diabetes.useParameters(patientId);

  if (!glycemiaStats || !glycemiaParameters) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent className={styles.content}>
        <div className={styles.row}>
          <div className={cx(styles.col, styles.itemsRow)}>
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.averageSensor')}
              value={glycemiaStats.meanGlycemia}
              unit={'mg/dL'}
              precision={0}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.estimatedHba1c')}
              value={glycemiaStats.hb1ac as number}
              unit={'%'}
              precision={1}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.coefVariation')}
              value={glycemiaStats.coefficientOfVariation as number}
              unit={'%'}
              precision={0}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.sensorUsage')}
              value={glycemiaStats.usagePercentage as number}
              unit={'%'}
              precision={0}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.nbHypo')}
              value={glycemiaStats.numberOfHypoglycemia}
              precision={0}
            />
          </div>
          {glycemiaStats.glycemiaRanges && (
            <div className={styles.col}>
              <Typography variant="body">
                {t('charts.timeBetweenThresholds')}
              </Typography>
              <GlycemiaBars
                width={300}
                height={275}
                data={glycemiaStats.glycemiaRanges}
                glycemiaThresholds={glycemiaParameters}
              />
            </div>
          )}
        </div>
        {glycemiaStats.agpProfile && (
          <div className={styles.row}>
            <AgpProfile
              agp={glycemiaStats.agpProfile}
              glycemiaParameters={glycemiaParameters}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
  `,
  col: css`
    width: 50%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
  `,
  itemsRow: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(16)};
  `,
  item: css``,
  gap: css``,
});
