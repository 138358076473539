import { css } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBars';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  className?: string;
};

export const GlycemiaStatsBGM = ({
  patientId,
  from,
  to,
  className,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from.toISO() as string,
      to: to.toISO() as string,
      glycemiaMeasurmentMethod: 'bgm',
    },
    {
      enabled: !!patientId,
    },
  );

  const { data: glycemiaThresholds } =
    Queries.diabetes.useParameters(patientId);

  if (
    !glycemiaStats ||
    !glycemiaStats.glycemiaDistribution ||
    !glycemiaThresholds
  ) {
    return null;
  }

  return (
    <Card className={className}>
      <CardHeader title={t('glycemia.title')} />
      <CardContent>
        <div className={styles.flexRow}>
          <div className={styles.columns}>
            <StatItem
              className={styles.item}
              title={t(
                'pages.patientMonitoring.glycemia.averageMeasuresPerDay',
              )}
              value={glycemiaStats.averageDailyMeasures as number}
              precision={1}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.averageReader')}
              value={glycemiaStats.meanGlycemia}
              unit={'mg/dL'}
              precision={0}
            />
            <StatItem
              className={styles.item}
              title={t('pages.patientMonitoring.glycemia.nbHypo')}
              value={glycemiaStats.numberOfHypoglycemia}
              precision={0}
            />
          </div>
          <div className={styles.columns}>
            <Typography variant="body">{t('charts.glycemiaSpread')}</Typography>
            <GlycemiaBars
              width={300}
              height={250}
              data={glycemiaStats.glycemiaDistribution}
              glycemiaThresholds={glycemiaThresholds}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  columns: css`
    flex: 0 0 50%;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(4)};
  `,
  item: css``,
});
