import { css } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

import { InsulinRepartition } from '../InsulinRepartition';
import { InsulinStatItem } from '../InsulinStats/InsulinStatItem';

type InsulinStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  insulinInjectionMethod?: string;
};

export const InsulinStatsBGM = ({
  patientId,
  from,
  to,
  insulinInjectionMethod,
}: InsulinStatsProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: insulinStats } = Queries.diabetes.useInsulinIndicators(
    {
      patientId,
      from: from.toISO() as string,
      to: to.toISO() as string,
      insulinInjectionMethod: insulinInjectionMethod ?? '',
    },
    {
      enabled: !!insulinInjectionMethod,
    },
  );

  if (!insulinStats) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <CardHeader title={t('insulin.title')} />
      <CardContent className={styles.content}>
        <InsulinStatItem
          title={t('insulin.averageNbInjectionsPerDay')}
          value={insulinStats?.averageNumberOfInjection ?? 0}
          precision={1}
        />
        <InsulinRepartition
          insulinStats={insulinStats}
          className={styles.repartiton}
        />
      </CardContent>
    </Card>
  );
};

const makeStyles = (_theme: Theme) => ({
  flexRow: css`
    display: flex;
    flex-direction: row;
  `,
  content: css`
    display: flex;
    flex-direction:;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
  `,
  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  repartiton: css`
    flex: 1;
    justify-content: flex-end;
  `,
});
