import { ThresholdStep } from '@/uiKit/molecules/thresholds/ThresholdStep';

import { AGPStats } from './AGPStatsModel';

export type GlycemiaMeasureType = 'interstitial' | 'capillary';
export type GlycemiaPeriod = 'breakfast' | 'lunch' | 'dinner' | 'before_bed';
export type GlycemiaPeriodTiming = 'before' | 'after';
export type GlycemiaDistribution = Record<ThresholdStep, number>;

export type GlycemiaIndicatorsResponse = {
  meanGlycemia: number;
  numberOfHypoglycemia: number;
  usagePercentage?: number;
  averageDailyMeasures?: number;
  glycemiaDistribution?: GlycemiaDistribution;
  glycemiaRanges?: GlycemiaDistribution;
  agpProfile?: AGPStats;
  hb1ac?: number;
  coefficientOfVariation?: number;
};

export const glycemiaIndicatorsDecoder = (
  value: any,
): GlycemiaIndicatorsResponse => {
  return {
    meanGlycemia: value['mean_glycemia'],
    numberOfHypoglycemia: value['number_of_hypoglycemia'],
    usagePercentage: value['usage_percentage'],
    averageDailyMeasures: value['average_daily_measures'],
    glycemiaDistribution: value['glycemia_distribution'],
    glycemiaRanges: value['glycemia_ranges'],
    agpProfile: value['agp_profile'],
    hb1ac: value['hb1ac'],
    coefficientOfVariation: value['coefficient_of_variation'],
  };
};
