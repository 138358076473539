import React, { useMemo } from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/loading/Loader.tsx';
import { Queries } from '@/queries/Queries.ts';

import { GlycemiaStatsContent } from './GlycemiaStatsContent';

export type GlycemiaStatsLayoutProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  className?: string;
};

/**
 * @deprecated This is being switched to the DM
 */
export const GlycemiaStats: React.FC<GlycemiaStatsLayoutProps> = ({
  patientId,
  from,
  to,
  className,
}) => {
  const { t } = useTranslation();
  const globalStats = Queries.diabetes.useGlobalStats({
    patientId,
    from,
    to,
  });

  const targetDurationStats = Queries.diabetes.useTargetDurationStats({
    patientId,
    from,
    to,
  });

  const diabetesDataDisplayMode =
    Queries.practitioner.useDiabetesDisplayMode(patientId);

  const content = useMemo(() => {
    if (
      globalStats.status !== 'success' ||
      targetDurationStats.status !== 'success' ||
      diabetesDataDisplayMode.status !== 'success'
    ) {
      // If any of the queries is not successful, show a loader
      return <Loader size="M" />;
    }

    return (
      <GlycemiaStatsContent
        patientId={patientId}
        from={from}
        to={to}
        targetDurationStats={targetDurationStats.data}
        glycemiaIndicatorsDisplayMode={
          diabetesDataDisplayMode.data.glycemia_indicators_display_mode
        }
        stats={globalStats.data.glycemia}
      />
    );
  }, [
    patientId,
    from,
    to,
    globalStats.status,
    globalStats.fetchStatus,
    targetDurationStats.status,
    targetDurationStats.fetchStatus,
    diabetesDataDisplayMode.status,
    diabetesDataDisplayMode.data?.glycemia_indicators_display_mode,
  ]);

  return (
    <Card elevation={0} className={className}>
      <CardHeader title={t('glycemia.title')} />
      <CardContent>{content}</CardContent>
    </Card>
  );
};
