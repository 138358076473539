import { css, cx } from '@emotion/css';
import { Card, CardContent, CardHeader, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

type GlycemiaStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  glycemiaType?: string;
  width?: string;
};

export const GlycemiaStatsBGMDG = ({
  patientId,
  from,
  to,
}: GlycemiaStatsProps) => {
  const { t } = useTranslation();
  const { data: glycemiaStats } = Queries.diabetes.useGlycemiaIndicators(
    {
      patientId,
      from: from.toISO() as string,
      to: to.toISO() as string,
      glycemiaMeasurmentMethod: 'bgm',
    },
    {
      enabled: !!patientId,
    },
  );
  const styles = useStyles(makeStyles);

  if (!glycemiaStats) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={t('glycemia.title')} />
      <CardContent>
        <div className={styles.flexRow}>
          <div className={styles.flexCol}>
            <div className={cx(styles.flexRow, styles.gap)}>
              <StatItem
                className={styles.item}
                title={t(
                  'pages.patientMonitoring.glycemia.averageMeasuresPerDay',
                )}
                value={glycemiaStats.averageDailyMeasures as number}
                precision={1}
              />
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.averageReader')}
                value={glycemiaStats.meanGlycemia}
                unit={'mg/dL'}
                precision={0}
              />
            </div>
            <div>
              <StatItem
                className={styles.item}
                title={t('pages.patientMonitoring.glycemia.nbHypo')}
                value={glycemiaStats.numberOfHypoglycemia}
                precision={0}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  item: css`
    flex: 0 1 30%;
  `,
  gap: css`
    gap: ${theme.spacing(8)};
  `,
  flexRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  flexCol: css`
    display: flex;
    flex-direction: column;
  `,
});
