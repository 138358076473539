import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { useStyles } from '@/hooks/useStyles';

import { GlycemiaStatsBGMDG } from './GlycemiaStatsBGMDG';
import { InsulinStatsBGM } from './InsulinStatsBGM';

type DiabetesStatsProps = {
  patientId: string;
  to: DateTime;
  from: DateTime;
  insulinInjectionMethod?: string;
};

export const DiabetesStatsBGMDG = ({
  patientId,
  from,
  to,
  insulinInjectionMethod,
}: DiabetesStatsProps) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <GlycemiaStatsBGMDG patientId={patientId} from={from} to={to} />
      <InsulinStatsBGM
        patientId={patientId}
        from={from}
        to={to}
        insulinInjectionMethod={insulinInjectionMethod}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    gap: ${theme.spacing(4)};
    flex-direction: row;
  `,
});
