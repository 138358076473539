import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Row } from '@/components/layout/Flex';
import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useStyles';
import { GlobalStatsGlycemiaReader } from '@/models/GlobalStatsModel';
import { compareValues } from '@/uiKit/organisms/GlycemiaStats/Common.ts';

export type GlycemiaReaderValuesProps = {
  data: GlobalStatsGlycemiaReader;
  gestational?: boolean;
};
/**
 * @deprecated This is being switched to the DM
 */
export const GlycemiaReaderValues: React.FC<GlycemiaReaderValuesProps> = ({
  data,
  gestational,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <Row className={styles.container}>
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.averageMeasuresPerDay')}
        value={data.daily_mean_count_measures}
        precision={1}
        variation={compareValues(
          data.daily_mean_count_measures,
          data.prev_daily_mean_count_measures,
        )}
      />
      <StatItem
        className={styles.item}
        title={t('pages.patientMonitoring.glycemia.averageReader')}
        value={data.mean_glycemia ?? 0}
        unit={'mg/dL'}
        precision={0}
        variation={compareValues(data.mean_glycemia, data.prev_mean_glycemia)}
        lowerIsBetter
      />
      {!gestational && (
        <StatItem
          className={styles.item}
          title={t('pages.patientMonitoring.glycemia.nbHypo')}
          value={data.count_hypoglycemia}
          precision={0}
          variation={compareValues(
            data.count_hypoglycemia,
            data.prev_count_hypoglycemia,
          )}
          lowerIsBetter
        />
      )}
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    column-gap: ${theme.spacing(8)};
    row-gap: ${theme.spacing(12)};
    flex-wrap: wrap;
  `,
  item: css`
    flex: 0 1 30%;
  `,
});
