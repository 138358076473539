import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { GlycemiaDistributionRange } from '@/models/TargetDurationStatsModel';
import { GlycemiaBars } from '@/uiKit/molecules/graphs/GlycemiaBarChart/GlycemiaBars.tsx';

type GlycemiaBarChartProps = {
  glycemiaDistribution: GlycemiaDistributionRange[];
  title: string;
  className?: string;
};

/**
 * @deprecated This is being switched to the DM
 */
const GlycemiaBarChart: React.FC<GlycemiaBarChartProps> = ({
  glycemiaDistribution,
  title,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div className={className}>
      <Typography variant="body">{t(title)}</Typography>
      {glycemiaDistribution ? (
        <GlycemiaBars
          width={300}
          height={275}
          data={glycemiaDistribution}
          className={styles.bars}
        />
      ) : null}
    </div>
  );
};

export type GlycemiaRepartitionProps = {
  glycemiaDistribution: GlycemiaDistributionRange[];
};

/**
 * @deprecated This is being switched to the DM
 */
export const GlycemiaRepartitionCGM = ({
  glycemiaDistribution,
}: GlycemiaRepartitionProps) => {
  return (
    <GlycemiaBarChart
      title={'charts.timeBetweenThresholds'}
      glycemiaDistribution={glycemiaDistribution}
    />
  );
};

/**
 * @deprecated This is being switched to the DM
 */
export const GlycemiaRepartitionBGM = ({
  glycemiaDistribution,
}: GlycemiaRepartitionProps) => {
  return (
    <GlycemiaBarChart
      title={'charts.glycemiaSpread'}
      glycemiaDistribution={glycemiaDistribution}
    />
  );
};

const makeStyles = () => ({
  bars: css``,
});
