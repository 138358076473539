import React, { useMemo } from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/loading/Loader.tsx';
import { Queries } from '@/queries/Queries.ts';
import { ContinuousInsulinStatsContent } from '@/uiKit/organisms/InsulinStats/ContinuousInsulinStatsContent.tsx';
import { DiscreteInsulinStatsContent } from '@/uiKit/organisms/InsulinStats/DiscreteInsulinStatsContent.tsx';

export type InsulinStatsProps = {
  patientId: string;
  from: DateTime;
  to: DateTime;
  className?: string;
};

/**
 * @deprecated This is being switched to the DM
 */
export const InsulinStats: React.FC<InsulinStatsProps> = ({
  patientId,
  from,
  to,
  className,
}) => {
  const { t } = useTranslation();

  const stats = Queries.insulin.useInsulinStats({
    patientId,
    from,
    to,
  });

  const content = useMemo(() => {
    if (stats.status !== 'success') {
      return <Loader size="M" />;
    }
    if (stats.data.type === 'discrete') {
      return <DiscreteInsulinStatsContent stats={stats.data} />;
    }
    return <ContinuousInsulinStatsContent stats={stats.data} />;
  }, [patientId, from, to, stats.status, stats.fetchStatus]);

  return (
    <Card className={className}>
      <CardHeader title={t('insulin.title')} />
      <CardContent>{content}</CardContent>
    </Card>
  );
};
